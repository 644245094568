@font-face {
  font-family: "Open Sans";
  src: url("/src/assets/fonts/Open_Sans/OpenSans-Regular.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Open Sans";
  src: url("/src/assets/fonts/Open_Sans/OpenSans-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Open Sans";
  src: url("/src/assets/fonts/Open_Sans/OpenSans-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Roboto";
  src: url("/src/assets/fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  src: url("/src/assets/fonts/Roboto/Roboto-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  src: url("/src/assets/fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Roboto";
  src: url("/src/assets/fonts/Roboto/Roboto-Black.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Montserrat";
  src: url("/src/assets/fonts/Montserrat/Montserrat-ExtraBold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Raleway";
  src: url("/src/assets/fonts/Raleway/Raleway-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Gadelica";
  src: url("/src/assets/fonts/Gadelica/Gadelica.otf");
  font-weight: 500;
}
* {
  box-sizing: border-box;
  margin: 0;
}
body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
html,
body,
#root {
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}
#root {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
  margin: 0px 20px;
}
header,
section,
footer {
  position: relative;
}
header .content,
section .content,
footer .content {
  position: relative;
  max-width: 1600px;
  width: 100%;
  height: 100%;
  padding: 0 32px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  header .content,
  section .content,
  footer .content {
    padding: 0 32px;
  }
}
@media (max-width: 425px) {
  header .content,
  section .content,
  footer .content {
    padding: 0 16px;
  }
}
.Footer {
  background: #374785;
  color: #fff;
  padding-top: 48px;
  padding-bottom: 48px;
  margin-bottom: 20px;
}
.Footer .footer-content {
  display: grid;
  grid-gap: 28px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "logo social contact" "legal legal legal";
}
.Footer .footer-content .logo {
  grid-area: logo;
}
.Footer .footer-content .logo .Icon {
  width: 280px;
}
.Footer .footer-content .mobile-logo {
  display: none;
}
.Footer .footer-content .social {
  grid-area: social;
}
.Footer .footer-content .social .social-links .social-link {
  background: #fff;
  color: #374785;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.Footer .footer-content .social .social-links .social-link:last-child {
  margin-right: 0px;
}
.Footer .footer-content .social .social-links .social-link:hover {
  background: #f2f2f2;
}
.Footer .footer-content .social .social-links .social-link:hover.twitter {
  color: #1da1f2;
}
.Footer .footer-content .social .social-links .social-link:hover.linked-in {
  color: #0a66c2;
}
.Footer .footer-content .contact {
  grid-area: contact;
}
.Footer .footer-content .legal {
  grid-area: legal;
}
@media (max-width: 768px) {
  .Footer .footer-content {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr;
    grid-template-areas: "logo" "social" "contact" "legal";
  }
  .Footer .footer-content .mobile-logo,
  .Footer .footer-content .contact,
  .Footer .footer-content .legal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Footer .footer-content .mobile-logo .Icon {
    height: 100px;
    width: auto;
  }
  .Footer .footer-content .logo {
    display: none;
  }
  .Footer .footer-content .contact .link {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.Navbar {
  top: 0;
  background: #fff;
  z-index: 2;
  position: sticky;
  width: calc(100% + (20px * 2));
  margin-left: -20px;
  padding: 0 20px;
  z-index: 3;
}
.Navbar .navbar-content {
  display: grid;
  grid-gap: 10px;
  grid-template-areas: "logo language" "logo links";
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  color: #374785;
  height: 100%;
  position: relative;
}
.Navbar .logo {
  display: flex;
  align-items: center;
  grid-area: logo;
  color: #374785;
  position: relative;
  overflow: hidden;
  height: 100%;
  margin-right: 32px;
}
.Navbar .logo .Icon {
  height: 69%;
  max-height: 78px;
}
.Navbar .links-wrapper {
  grid-area: links;
  display: flex;
  justify-content: flex-end;
  align-self: flex-start;
}
.Navbar .links-wrapper .links {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Navbar .links-wrapper .links .link {
  margin-right: 36px;
  position: relative;
  font-weight: 500;
}
.Navbar .links-wrapper .links .sub-menu-link {
  padding-bottom: 16px;
  margin-bottom: -16px;
}
.Navbar .links-wrapper .links .sub-menu {
  position: absolute;
  background: #fff;
  padding: 24px 16px;
  border-radius: 4px;
  border: 2px solid #374785;
  top: calc(100%);
  min-width: 220px;
}
.Navbar .links-wrapper .links .sub-menu:before,
.Navbar .links-wrapper .links .sub-menu:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  left: calc(50% - 12px);
}
.Navbar .links-wrapper .links .sub-menu:before {
  top: -10px;
  border-color: transparent transparent #374785 transparent;
}
.Navbar .links-wrapper .links .sub-menu:after {
  top: -7px;
  border-color: transparent transparent #fff transparent;
}
.Navbar .links-wrapper .links .sub-menu .sublink {
  margin-bottom: 16px;
}
.Navbar .links-wrapper .links .sub-menu .sublink:last-child {
  margin-bottom: 0px !important;
}
.Navbar .links-wrapper .links .sub-menu .link {
  margin-right: 0px;
}
.Navbar .language-link {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  grid-area: language;
  font-family: 'Gadelica', sans-serif;
  font-weight: 400;
  margin-right: 2px;
}
.Navbar .button {
  font-weight: 500;
}
@media (max-width: 768px) {
  .Navbar {
    position: relative;
  }
  .Navbar .navbar-content {
    grid-template-areas: "logo mobile-menu";
    grid-template-columns: 1fr auto;
  }
  .Navbar .nav-language-link,
  .Navbar .links-wrapper {
    display: none;
  }
  .Navbar .language-link {
    justify-content: flex-start;
    margin-bottom: 24px !important;
  }
  .Navbar .logo .Icon {
    height: 50%;
  }
  .Navbar .NavMenuMobile {
    display: flex;
    justify-content: flex-end;
    grid-area: mobile-menu;
  }
}
.NavMenuMobile {
  display: none;
}
.NavMenuMobile .hamburger {
  position: relative;
  z-index: 1;
  color: #374785;
}
.NavMenuMobile .hamburger g {
  transform-origin: 0px 0px !important;
}
.NavMenuMobile .menu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(255,255,255,0.98);
  z-index: 1;
}
.NavMenuMobile .menu .menu-content {
  width: 100%;
  height: calc(100% - 88px);
  margin-top: 88px;
  padding: 32px 8px;
}
.NavMenuMobile .menu .menu-content .list {
  list-style: none;
  padding-left: 0px;
}
.NavMenuMobile .menu .menu-content .list li {
  margin-bottom: 28px;
}
.NavMenuMobile .menu .menu-content .list li.indent {
  margin-left: 12px;
}
.NavMenuMobile .menu .menu-content .list .menu-title {
  margin-bottom: 24px;
}
@media (max-width: 768px) {
}
.button {
  position: relative;
  font-size: 14px;
  padding: 4px 24px;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 3px;
  border-style: solid;
  min-height: 42px;
  text-decoration: none;
  background: #374785;
  border-color: #374785;
  color: #fff;
  white-space: nowrap;
  transition-property: background-color, color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.7, 0.2);
}
.button:hover {
  background: #4559a7;
  border-color: #4559a7;
  cursor: pointer;
}
.button:disabled {
  background: #2f3c71;
  border-color: #2f3c71;
}
.button.l {
  font-size: 15px;
  padding: 8px 28px;
}
.row {
  display: flex;
}
.row.inline {
  display: inline-flex;
}
.row.even > * {
  flex: 1;
}
.row.reverse {
  flex-direction: row-reverse;
}
.row.space-between {
  justify-content: space-between;
}
.row.space-around {
  justify-content: space-around;
}
.row.space-evenly {
  justify-content: space-evenly;
}
.row.x-left {
  justify-content: flex-start;
}
.row.x-right {
  justify-content: flex-end;
}
.row.x-center {
  justify-content: center;
}
.row.y-top {
  align-items: flex-start;
}
.row.y-center {
  align-items: center;
}
.row.y-bottom {
  align-items: flex-end;
}
.row.y-baseline {
  align-items: baseline;
}
.row.xy-center {
  justify-content: center;
  align-items: center;
}
.row .align-self-top {
  align-self: flex-start;
}
.column {
  display: flex;
  flex-direction: column;
}
.column.x-center {
  align-items: center;
}
.column.x-left {
  align-items: flex-start;
}
.column.x-right {
  align-items: flex-end;
}
.column.y-center {
  justify-content: center;
}
.column.y-bottom {
  justify-content: flex-end;
}
.column .self-right {
  align-self: flex-end;
}
.column.xy-center {
  justify-content: center;
  align-items: center;
}
input,
textarea {
  display: block;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  resize: vertical;
  font-family: 'Roboto', sans-serif;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  border: 1px solid none;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: #fff 5000s ease-in-out 0s;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
form {
  position: relative;
}
form .row {
  margin-bottom: 16px;
}
form .row:last-child {
  margin-bottom: 0px;
}
form .row .Field {
  margin-right: 16px;
}
form .row .Field:last-child {
  margin-right: 0px;
}
.Field {
  padding: 12px 16px;
  flex: 1;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
}
.Field label {
  display: flex;
  justify-content: space-between;
  color: #374785;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.Field label .star {
  font-size: 12px;
  color: #33a1ff;
  margin-top: -8px;
}
.Field input,
.Field textarea {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}
.Field input::placeholder,
.Field textarea::placeholder {
  font-weight: 400;
  opacity: 0.5;
}
.Field .error-message {
  font-size: 14px;
  line-height: 24px;
  color: #e20000;
  font-weight: 400;
}
.LoadingDots {
  color: currentColor;
  margin-left: 2px;
}
.LoadingDots span {
  font-size: 16px;
  animation: dots-1 1s infinite steps(1);
}
.LoadingDots span:first-child + span {
  animation-name: dots-2;
}
.LoadingDots span:first-child + span + span {
  animation-name: dots-3;
}
.error-message {
  color: #e20000;
}
.success-message {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.95);
  left: 0;
  top: 0;
}
.success-message .Icon {
  color: #374785;
  width: 48px;
  margin-bottom: 24px;
}
@-moz-keyframes dots-1 {
  from {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
@-webkit-keyframes dots-1 {
  from {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
@-o-keyframes dots-1 {
  from {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
@keyframes dots-1 {
  from {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
@-moz-keyframes dots-2 {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes dots-2 {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-o-keyframes dots-2 {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes dots-2 {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-moz-keyframes dots-3 {
  from {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
@-webkit-keyframes dots-3 {
  from {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
@-o-keyframes dots-3 {
  from {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
@keyframes dots-3 {
  from {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
.Header {
  position: relative;
  height: 440px;
  background-position: center;
  background-size: cover;
  z-index: 1;
}
.Header.l {
  height: 620px;
  height: calc(var(--vh, 1vh) * 100 - 120px - 20px);
}
.Header:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(55,71,133,0.2);
}
.Header:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: -1;
}
.Header .content {
  display: flex;
  padding: 32px 32px;
  overflow: hidden;
}
.Header h1 {
  color: #fff;
}
.Header .video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
@media (max-width: 425px) {
  .Header {
    height: 320px;
  }
  .Header .content {
    padding: 32px 16px;
  }
}
.link {
  color: #374785;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  text-decoration: none;
  text-decoration: none;
  transition-property: color, background;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.7, 0.2);
}
.link:hover {
  color: #4559a7;
  cursor: pointer;
}
.link.secondary {
  color: #33a1ff;
}
.link.secondary:hover {
  color: #0084f5;
}
.link.light {
  color: #fff;
}
.link.light:hover {
  color: #ccc;
}
.link.s {
  font-size: 15px;
}
.link.l {
  font-size: 18px;
}
.LoadingSpinner.loading-spinner-container {
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 600px;
}
.LoadingSpinner.loading-spinner-container .loading-spinner {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0.25rem solid #e8f3f7;
  border-top-color: #374785;
  -webkit-animation: spin 0.85s infinite linear;
  animation: spin 0.85s infinite linear;
}
.LoadingSpinner.full-page {
  min-height: 100vh;
  position: relative;
  z-index: 3;
  background: #fff;
}
.LoadingSpinner.section {
  min-height: 400px;
}
.LoadingSpinner.small {
  min-height: auto;
}
.LoadingSpinner.small .loading-spinner {
  width: 20px;
  height: 20px;
}
.LoadingSpinner.light .loading-spinner {
  border-color: #fff;
  border-top-color: $grey-light;
}
@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.Section {
  padding-top: 48px;
  padding-bottom: 48px;
}
.Section .content {
  padding-top: 24px;
  padding-bottom: 24px;
}
.Section.l .content {
  padding-top: 48px;
  padding-bottom: 48px;
}
.Section.light {
  background: #e8f3f7;
}
.Section.light .content {
  color: #374785;
}
.Section.dark {
  background: #374785;
}
.Section.dark .content {
  color: #fff;
}
.Section.ciaran-gradient {
  background-image: radial-gradient(circle at 5%, #b6c5cc 20%, #a5b3bb 35%);
}
.Section.ciaran-gradient .content,
.Section.ciaran-gradient p {
  color: #fff;
}
.Section.cathal-gradient {
  background-image: radial-gradient(circle at 75%, #e3eaf2 10%, rgba(208,217,226,0.7) 20%);
}
@media (max-width: 1100px) {
  .Section.ciaran-gradient {
    background-image: radial-gradient(circle at 25%, #b1bfc8 30%, #a5b3bb 50%);
  }
  .Section.cathal-gradient {
    background-image: radial-gradient(circle at 80%, #dfe7ef 25%, #e1e9f1 50%);
  }
}
@media (max-width: 768px) {
  .Section {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .Section .content {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .Section.ciaran-gradient {
    background-image: radial-gradient(circle at 25%, #b0bfc6 30%, #a4b5c0 50%);
  }
  .Section.cathal-gradient {
    background-image: radial-gradient(circle at -40% 75%, #e3eaf1 45%, #dae3ec 60%);
  }
}
.m-0 {
  margin: 0px;
}
.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.m-4 {
  margin: 4px;
}
.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}
.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.mt-4 {
  margin-top: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.m-8 {
  margin: 8px;
}
.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.m-12 {
  margin: 12px;
}
.mx-12 {
  margin-left: 12px;
  margin-right: 12px;
}
.my-12 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.mt-12 {
  margin-top: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.ml-12 {
  margin-left: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.m-16 {
  margin: 16px;
}
.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.m-20 {
  margin: 20px;
}
.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.m-24 {
  margin: 24px;
}
.mx-24 {
  margin-left: 24px;
  margin-right: 24px;
}
.my-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.m-32 {
  margin: 32px;
}
.mx-32 {
  margin-left: 32px;
  margin-right: 32px;
}
.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.m-36 {
  margin: 36px;
}
.mx-36 {
  margin-left: 36px;
  margin-right: 36px;
}
.my-36 {
  margin-top: 36px;
  margin-bottom: 36px;
}
.mt-36 {
  margin-top: 36px;
}
.mb-36 {
  margin-bottom: 36px;
}
.ml-36 {
  margin-left: 36px;
}
.mr-36 {
  margin-right: 36px;
}
.m-40 {
  margin: 40px;
}
.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}
.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.m-48 {
  margin: 48px;
}
.mx-48 {
  margin-left: 48px;
  margin-right: 48px;
}
.my-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.m-64 {
  margin: 64px;
}
.mx-64 {
  margin-left: 64px;
  margin-right: 64px;
}
.my-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}
.mt-64 {
  margin-top: 64px;
}
.mb-64 {
  margin-bottom: 64px;
}
.ml-64 {
  margin-left: 64px;
}
.mr-64 {
  margin-right: 64px;
}
.m-80 {
  margin: 80px;
}
.mx-80 {
  margin-left: 80px;
  margin-right: 80px;
}
.my-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}
.mt-80 {
  margin-top: 80px;
}
.mb-80 {
  margin-bottom: 80px;
}
.ml-80 {
  margin-left: 80px;
}
.mr-80 {
  margin-right: 80px;
}
.m-100 {
  margin: 100px;
}
.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}
.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-100 {
  margin-bottom: 100px;
}
.ml-100 {
  margin-left: 100px;
}
.mr-100 {
  margin-right: 100px;
}
.p-0 {
  padding: 0px;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.p-4 {
  padding: 4px;
}
.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}
.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.pt-4 {
  padding-top: 4px;
}
.pb-4 {
  padding-bottom: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.pr-4 {
  padding-right: 4px;
}
.p-8 {
  padding: 8px;
}
.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.p-12 {
  padding: 12px;
}
.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.pt-12 {
  padding-top: 12px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pl-12 {
  padding-left: 12px;
}
.pr-12 {
  padding-right: 12px;
}
.p-16 {
  padding: 16px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.p-20 {
  padding: 20px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.p-24 {
  padding: 24px;
}
.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.p-32 {
  padding: 32px;
}
.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.p-36 {
  padding: 36px;
}
.px-36 {
  padding-left: 36px;
  padding-right: 36px;
}
.py-36 {
  padding-top: 36px;
  padding-bottom: 36px;
}
.pt-36 {
  padding-top: 36px;
}
.pb-36 {
  padding-bottom: 36px;
}
.pl-36 {
  padding-left: 36px;
}
.pr-36 {
  padding-right: 36px;
}
.p-40 {
  padding: 40px;
}
.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.p-48 {
  padding: 48px;
}
.px-48 {
  padding-left: 48px;
  padding-right: 48px;
}
.py-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.p-64 {
  padding: 64px;
}
.px-64 {
  padding-left: 64px;
  padding-right: 64px;
}
.py-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}
.pt-64 {
  padding-top: 64px;
}
.pb-64 {
  padding-bottom: 64px;
}
.pl-64 {
  padding-left: 64px;
}
.pr-64 {
  padding-right: 64px;
}
.p-80 {
  padding: 80px;
}
.px-80 {
  padding-left: 80px;
  padding-right: 80px;
}
.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.pt-80 {
  padding-top: 80px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pl-80 {
  padding-left: 80px;
}
.pr-80 {
  padding-right: 80px;
}
.m-100 {
  margin: 100px;
}
.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}
.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-100 {
  margin-bottom: 100px;
}
.ml-100 {
  margin-left: 100px;
}
.mr-100 {
  margin-right: 100px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}
h1 {
  font-size: 72px;
  line-height: 94px;
  font-weight: 500;
}
h2 {
  font-size: 48px;
  line-height: 56px;
}
h3 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 500;
}
h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}
h5 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
h6 {
  font-size: 15px;
  font-weight: 500;
}
p {
  font-size: 17px;
  line-height: 32px;
  color: #7d8290;
  font-family: 'Open Sans', sans-serif;
}
p.s {
  font-size: 16px;
  line-height: 22px;
}
p.l {
  font-size: 20px;
  line-height: 32px;
}
small {
  font-size: 12px;
  line-height: 16px;
}
br {
  content: "";
  display: block;
  height: 16px;
}
.text-light {
  color: #fff;
}
.text-primary {
  color: #374785;
}
.text-default {
  color: #7d8290;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-bold {
  font-weight: 600;
}
.text-extra-bold {
  font-weight: 700;
}
.text-thin {
  font-weight: 300;
}
.text-nowrap {
  white-space: nowrap;
}
.text-underline {
  text-decoration: underline;
}
@media (max-width: 540px) {
  h1 {
    font-size: 50px;
    line-height: 60px;
  }
  h2 {
    font-size: 36px;
    line-height: 48px;
  }
  h3 {
    font-size: 32px;
    line-height: 40px;
  }
}
.View {
  flex: 1;
}
.ComingSoon {
  padding: 48px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.ComingSoon .logo-wrapper {
  display: flex;
  width: 100%;
  max-width: 500px;
}
.ComingSoon .logo-wrapper svg {
  width: 100% !important;
  height: 100% !important;
}
.ComingSoon .website-message {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 32px;
  margin-bottom: 48px;
}
.ComingSoon .contact-details .contact-detail {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
  text-align: center;
  color: currentColor;
  display: flex;
  justify-content: center;
}
@media (max-width: 1024px) {
  .ComingSoon .website-message {
    font-size: 15px;
    margin-bottom: 32px;
  }
  .ComingSoon .contact-details .contact-detail {
    font-size: 13px;
  }
}
@media (max-width: 425px) {
  .ComingSoon .website-message {
    font-size: 13px;
  }
  .ComingSoon .contact-details .contact-detail {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .CallToAction {
    margin-top: 48px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .CallToAction .content {
    flex-direction: column;
  }
  .CallToAction .content .text {
    margin-right: 0px;
    margin-bottom: 32px;
    text-align: center;
  }
}
.TextBlock .text-wrapper {
  z-index: 1;
}
@media (max-width: 768px) {
  .TextBlock .content {
    flex-direction: column;
  }
  .TextBlock .content .Title.right {
    text-align: left;
    align-items: flex-start;
  }
  .TextBlock .content p {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .TextBlock .content .text-wrapper {
    margin-left: 0px;
    margin-right: 0px;
  }
  .TextBlock .content .image-wrapper {
    margin-top: 48px;
    order: 1;
  }
}
.Title {
  position: relative;
}
.Title .title {
  color: #374785;
  font-weight: 700;
  max-width: 500px;
}
.Title .subtitle {
  color: #33a1ff;
  font-weight: 600;
  margin-left: 2px;
  margin-bottom: 4px;
}
.Title .number {
  color: rgba(55,71,133,0.1);
  font-size: 104px;
  line-height: 80px;
  top: -16px;
  left: -8px;
  position: absolute;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 6px;
}
.Title.right {
  text-align: right;
  align-items: flex-end;
}
.Title.right .title {
  margin-left: auto;
}
.Title.right .number {
  right: -8px;
}
.Title.light .title {
  color: #fff;
}
.Title.light .number {
  color: rgba(255,255,255,0.1);
}
.Title.l .subtitle {
  margin-bottom: 12px;
}
@media (max-width: 768px) {
  .Title {
    margin-bottom: 16px;
  }
}
.WhatDoWeOffer .usps {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 80px 32px;
}
@media (max-width: 1240px) {
  .WhatDoWeOffer .usps {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .WhatDoWeOffer .usps {
    grid-template-columns: 1fr;
  }
}
.WhatDoWeOffer .WhatDoWeOfferItem {
  background: #fff;
  box-shadow: -4px 4px 15px rgba(55,71,133,0.25);
  border-radius: 4px;
  color: #374785;
  font-weight: 500;
}
.WhatDoWeOffer .WhatDoWeOfferItem .icon-wrapper {
  display: flex;
  justify-content: center;
  background: #fff;
  padding: 12px;
  box-shadow: -4px 4px 15px rgba(55,71,133,0.25);
  margin: 0 auto;
  border-radius: 50%;
  margin-top: -32px;
}
.WhatDoWeOffer .WhatDoWeOfferItem .icon-wrapper .Icon {
  width: 48px;
  height: 48px;
  padding: 4px;
}
.WhatDoWeOffer .WhatDoWeOfferItem:nth-child(2) .icon-wrapper .Icon {
  padding: 2px;
}
.WhatDoWeOffer .WhatDoWeOfferItem .title {
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0 8px;
  min-height: 48px;
}
.WhatDoWeOffer .WhatDoWeOfferItem .description {
  padding: 0 12px;
  margin-bottom: 12px;
  text-align: center;
}
.WhatDoWeOffer .WhatDoWeOfferItem p {
  flex: 2;
}
.WhatDoWeOffer .WhatDoWeOfferItem .link {
  text-align: center;
  padding: 12px 12px 12px;
  padding-left: 22px;
}
.WhatDoWeOffer .WhatDoWeOfferItem .link .Icon {
  display: flex;
  width: 14px;
  width: 10px;
  height: 14px;
  height: 10px;
  margin-left: 8px;
  margin-top: -2px;
}
.WhatDoWeOffer .WhatDoWeOfferItem .link:hover {
  color: #0084f5;
}
@media (max-width: 768px) {
  .WhatDoWeOffer .Title {
    margin-bottom: 80px;
  }
}
.OurProcess .process-items {
  display: grid;
  grid-gap: 32px;
  justify-content: space-around;
  justify-content: space-evenly;
}
@media (max-width: 1240px) {
  .OurProcess .process-items {
    grid-template-columns: 1fr !important;
    margin-top: 32px;
  }
  .OurProcess .process-items .arrow .Icon {
    transform: rotate(90deg);
  }
}
.OurProcess .process-items .ProcessItem {
  position: relative;
  height: 100%;
}
.OurProcess .process-items .ProcessItem .Icon {
  color: #374785;
}
.OurProcess .tabs .tab {
  color: #d4d4d4;
  margin-right: 24px;
  font-weight: 700;
  letter-spacing: 0.75px;
  cursor: pointer;
}
.OurProcess .tabs .tab.active {
  color: #33a1ff !important;
}
.OurProcess .tabs .tab:hover {
  color: rgba(51,161,255,0.75);
}
.OurProcess .tabs .tab:last-child {
  margin-right: 0px;
}
@media (max-width: 540px) {
  .OurProcess .tabs {
    flex-direction: column;
    align-items: flex-start;
  }
  .OurProcess .tabs .tab {
    margin-bottom: 24px;
    margin-right: 0px;
  }
  .OurProcess .tabs .tab:last-child {
    margin-bottom: 8px;
  }
}
.About .CallToAction {
  margin-top: 0px !important;
}
.our-company div:not(.Title) {
  flex: 1.2;
}
.StaffBlock {
  overflow: hidden;
}
.StaffBlock .image-wrapper {
  overflow: hidden;
}
.StaffBlock .text-wrapper {
  flex: 1.2;
}
.StaffBlock .ciaran {
  position: absolute;
  width: 480px;
  top: -48px;
}
.StaffBlock .cathal {
  position: absolute;
  width: 540px;
  top: -48px;
}
@media (max-width: 1100px) {
  .StaffBlock .ciaran,
  .StaffBlock .cathal {
    top: auto;
    bottom: -48px;
  }
}
@media (max-width: 768px) {
  .StaffBlock {
    padding-bottom: 0px !important;
  }
  .StaffBlock .content {
    padding-bottom: 0px;
  }
  .StaffBlock .ciaran,
  .StaffBlock .cathal {
    position: relative;
    bottom: 0px;
  }
  .StaffBlock .ciaran,
  .StaffBlock .cathal {
    top: 0;
  }
}
.Contact .contact-details {
  flex: 1;
  padding-top: 32px;
}
.Contact .contact-details .contact-detail {
  color: #374785;
  margin-bottom: 32px;
}
.Contact .contact-details .contact-detail:last-child {
  margin-bottom: 0px;
}
.Contact .contact-details .contact-detail .Icon {
  color: #374785;
  width: 24px;
  width: 24px;
  flex: 0 0 24px;
  margin-right: 20px;
}
.Contact .contact-details .contact-detail .Icon.phone {
  width: 18px;
  height: 24px;
}
.Contact .contact-form {
  position: relative;
  flex: 2;
  margin-left: 64px;
  padding: 32px;
  box-shadow: 2px 3px 15px rgba(55,71,133,0.15);
  border-radius: 4px;
}
@media (max-width: 1024px) {
  .Contact .details-and-form {
    flex-direction: column;
    margin-top: 48px;
    margin-bottom: 0px;
  }
  .Contact .details-and-form .contact-details {
    padding-top: 0px;
    margin-bottom: 32px;
  }
  .Contact .details-and-form .contact-details br {
    display: revert;
    display: unset;
    margin-right: 8px;
  }
  .Contact .details-and-form .contact-details br:after {
    content: ',';
    position: relative;
  }
  .Contact .details-and-form .contact-form {
    margin-left: 0px;
  }
}
@media (max-width: 768px) {
  .Contact .contact-form {
    padding: 16px;
    margin-top: 12px;
  }
  .Contact .contact-form .row {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .Contact .contact-form:first-child {
    margin-top: 0px;
  }
  .Contact .contact-form .Field {
    margin-right: 0px;
    margin-bottom: 16px;
  }
}
